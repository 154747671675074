import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe, registerLocaleData } from '@angular/common';
import { MatSharedModule } from '../mat-shared/mat-shared.module';
import { DinamicFormComponent } from './dinamic-form/dinamic-form.component';
import { FieldsProductsComponent } from './fields-products/fields-products.component';
import { BcSharedModule } from '../bc-shared/bc-shared.module';
import { ListNewsComponent } from './list-news/list-news.component';
import { RouterModule } from '@angular/router';
import { PaymentModalComponent } from './payment-modal/payment-modal.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfoPolicyComponent } from './info-policy/info-policy.component';
import { BeneficiariesComponent } from './beneficiaries/beneficiaries.component';
import { PaymentComponent } from './payment/payment.component';
import { InformationInsuredComponent } from './information-insured/information-insured.component';
import { BasicInfoComponent } from './basic-info/basic-info.component';
import { PrincipalComponent } from './principal/principal.component';
import { CommonsModule } from '../commons/commons.module';
import localeCO from '@angular/common/locales/es-CO';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { DirectivesModule } from '../directives/directives.module';
import { DateHeaderComponent } from './date-header/date-header.component';
import { PepValidationComponent } from './pep-validation/pep-validation.component';
import { ResultExpeditionComponent } from 'src/app/modules/home/components/product/components/result-expedition/result-expedition.component';
import { SarlaftFieldsNaturalPersonComponent } from './sarlaft-fields-natural-person/sarlaft-fields-natural-person.component';
import { ShareholderInformationComponent } from './shareholder-information/shareholder-information.component';
import { DeclarationIllnessesComponent } from './declaration-illnesses/declaration-illnesses.component';
import { IllnessSelectComponent } from './declaration-illnesses/illness-select/illness-select.component';
import { ShareholderValidationComponent } from './shareholder-validation/shareholder-validation.component';
import { PersonalDataComponent } from './personal-data/personal-data.component';
import { ListcontrolAuthComponent } from './listcontrol-auth/listcontrol-auth.component';
import { ViewnewsComponent } from './viewnews/viewnews.component';
registerLocaleData(localeCO, 'es');

@NgModule({
    declarations: [
        BeneficiariesComponent,
        InfoPolicyComponent,
        PaymentComponent,
        InformationInsuredComponent,
        BasicInfoComponent,
        PrincipalComponent,
        DinamicFormComponent,
        FieldsProductsComponent,
        ListNewsComponent,
        PaymentModalComponent,
        BreadcrumbsComponent,
        DateHeaderComponent,
        PepValidationComponent,
        ResultExpeditionComponent,
        SarlaftFieldsNaturalPersonComponent,
        DeclarationIllnessesComponent,
        IllnessSelectComponent,
        ShareholderInformationComponent,
        ShareholderValidationComponent,
        PersonalDataComponent,
        ListcontrolAuthComponent,
        ViewnewsComponent
    ],
    entryComponents: [ViewnewsComponent],
    imports: [
        CommonModule,
        BcSharedModule,
        MatSharedModule,
        FormsModule,
        ReactiveFormsModule,
        CommonsModule,
        RouterModule,
        PipesModule,
        DirectivesModule
    ],
    exports: [
        BeneficiariesComponent,
        InfoPolicyComponent,
        PaymentComponent,
        InformationInsuredComponent,
        BasicInfoComponent,
        PrincipalComponent,
        DinamicFormComponent,
        FieldsProductsComponent,
        ListNewsComponent,
        BreadcrumbsComponent,
        DateHeaderComponent,
        PepValidationComponent,
        SarlaftFieldsNaturalPersonComponent,
        PersonalDataComponent,
        ListcontrolAuthComponent
    ],
    providers: [
        TitleCasePipe,
        { provide: LOCALE_ID, useValue: 'es' }
    ]
})
export class ComponentsModule {
}
