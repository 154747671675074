// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true
};

export const endpoint = {
  endpointParametrizationProd: "https://captaciones-int-dev.apps.ambientesbc.com/seguros/settings/api/",
  endpointProductsProd: "https://captaciones-int-dev.apps.ambientesbc.com/seguros/intventas/",
  endpointPolicyProd: "https://captaciones-int-dev.apps.ambientesbc.com/seguros/policyvoluntarios/api/",
  apiurl: "https://captaciones-int-dev.apps.ambientesbc.com/seguros/intventas/",
  endpointLists: "https://captaciones-int-dev.apps.ambientesbc.com/seguros/intventas/list/",
  urlSeguros: "https://captaciones-int-dev.apps.ambientesbc.com/seguros/",
  urlCardif: "https://webview-uat.cardif.com.co/wv_bancolombia/"
}

export const msal = {
  clientId: "e627b993-7f96-47fa-b866-94833fcebbc6",
  redirectUri: "https://seguros-dev-integrador.apps.ambientesbc.com",
  tenant: '428f4e2e-13bf-4884-b364-02ef9af41a1d'
}